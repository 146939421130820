import React , { useRef } from 'react';
import styles from "../styles/contact.module.css";
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';

function Contact() {

  const variants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: "-100vw",
      scale: 1.2,
    },
  };

  const form = useRef();

	const toastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored"
		};


  const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const {from_name , user_email , message} = form.current.elements
			if(from_name.value === '' || user_email.value === '' || message.value === ''){
				toast.error('Todos los campos son obligatorios', toastOptions);
			} else {
				await emailjs.sendForm('service_x6fobha', 'template_9l8nrtu', form.current , 'CJNeSrbyt1eO3nYjj');;
				form.current.reset();
				toast.success("Successfully registered" , toastOptions);			
			}
			
		} catch (error) {
			console.log(error)
		}

	};


  const pageTransition = {
    duration: 0.5,
  };
  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
      className="docs"
    >
      <h1 className={styles.title}>Contact</h1>
          <p className={styles.subtitle}>
            Box to leave a message, requesting a demo of a specific software or
            a more personal enquiry.
          </p>
      <div className={styles.container}>
        <div>
          <form className="formulario" ref={form} onSubmit={handleSubmit} action="">
            <div>
						  <input type="text" name="from_name"  placeholder="Name" className="nes-input" />
					  </div>
					  <div>
						  <input type="text" name="user_email"  placeholder="E-mail" className="nes-input" />
					  </div>
					  <div>
						  <textarea id="textarea_field" name="message" placeholder="Message"  className="nes-textarea"></textarea>
					  </div>
					  <div>
						  <span>
							  <button className="btnForm">Consult</button>
						  </span>
					  </div>
				  </form>

        </div>
        <div className={styles.footer}>
          <h3 className={styles.titleFooter}>
            Consult here or you can send me an email
          </h3>
          <p className={styles.subtitleFooter}>
            <bold>lreyes@udesa.edu.ar</bold>
          </p>
        </div>
      </div>
      <ToastContainer />
    </motion.div>
  );
}

export default Contact;
